import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import CoverImageText from '../components/CoverImageText';
import { h1, h3 } from '../styles/typography';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import { media } from '../styles';
import Rte from '../components/Rte';
import Row from '../components/Row';
import BorderSeparator from '../components/BorderSeparator';
import { Helmet } from 'react-helmet';

const StyledTitle = styled.h2`
    ${h1};
    position: relative;
    position: sticky;
    padding: ${props => props.theme.spacing.s60} ${sizeAsBase(2)};
    top: ${props => props.theme.sizes.headerHeight}px;
`;

const StyledBlock = styled.div`
    position: relative;
    background: ${props => props.theme.colors.greyLightAlt};
`;

const StyledRow = styled(Row)`
    overflow: initial;

    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 1px;
        background: ${props => props.theme.colors.grey};
    }

    &:before {
        display: none;
        left: 0;
        right: auto;
    }

    ${media.mobile`
        &:before {
            display: block;
        }
    `}
`;

const StyledLeft = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;

    ${media.large`
        padding-left: ${sizeAsMax(2)};
        padding-right: ${sizeAsMax(2)};
    `}

    ${media.mobile`
        position: relative;
        top: auto;
        left: auto;
        padding-top: ${props => props.theme.spacing.s30};
    `}
`;

const StyledRight = styled.div`
    margin-left: ${sizeAsBase(30)};
    margin-right: ${sizeAsBase(-3)};
    background: ${props => props.theme.colors.white};
    padding: ${props => props.theme.spacing.s80} ${sizeAsBase(5)}
        ${props => props.theme.spacing.s80} ${sizeAsBase(5)};

    ${media.large`
        margin-right: ${sizeAsMax(-3)};
        margin-left: ${sizeAsMax(30)};
        padding-left: ${sizeAsMax(2)};
        padding-right: ${sizeAsMax(5)};
    `}

    ${media.mobile`
        width: auto;
        margin-left: ${sizeAsBase(-3)};
        margin-right: ${sizeAsBase(-3)};
        padding-left: ${sizeAsBase(5)};
        padding-right: ${sizeAsBase(5)};
    `}
`;

const BlockPosts = ({ title, children }) => {
    return (
        <StyledBlock>
            <StyledRow>
                <StyledLeft>
                    <StyledTitle>{title}</StyledTitle>
                </StyledLeft>
                <StyledRight>{children}</StyledRight>
            </StyledRow>
        </StyledBlock>
    );
};

const StyledPost = styled.div`
    h3 {
        ${h3};
        margin-bottom: ${props => props.theme.spacing.s40};
    }

    & + & {
        margin-top: ${props => props.theme.spacing.s60};
    }
`;

const Research = ({ data }) => {
    const {
        seo_title,
        seo_description,
        title,
        cover_text,
        cover_images,
        title_publications,
        publications,
        title_research,
        researchs
    } = data.page.data;

    const filteredResearchs = researchs
        .filter(({ research }) => research !== null)
        .map(({ research }) => research);

    return (
        <div>
            <Helmet>
                <title>{seo_title}</title>
                {seo_description && <meta name="description" content={seo_description} />}
            </Helmet>
            <CoverImageText
                text={cover_text.html}
                images={cover_images}
                color="red"
                title={title.text}
            />
            <BlockPosts title={title_publications}>
                <StyledPost>
                    <Rte dangerouslySetInnerHTML={{ __html: publications.html }} />
                </StyledPost>
            </BlockPosts>
            <BorderSeparator />
            <BlockPosts title={title_research}>
                {filteredResearchs.map(({ id, document }) => (
                    <StyledPost key={id}>
                        <h3>{document[0].data.title.text}</h3>
                        <Rte dangerouslySetInnerHTML={{ __html: document[0].data.content.html }} />
                    </StyledPost>
                ))}
            </BlockPosts>
        </div>
    );
};

export default Research;

export const pageQuery = graphql`
    query PageResearchQuery($lang: String!) {
        page: prismicPageResearch(lang: { eq: $lang }) {
            data {
                seo_title
                seo_description
                title {
                    text
                }
                cover_text {
                    html
                }
                cover_images {
                    image {
                        local: localFile {
                            ...CoverImage
                        }
                    }
                }
                title_publications
                publications {
                    html
                }
                title_research
                researchs {
                    research {
                        id
                        document {
                            ... on PrismicResearchPost {
                                data {
                                    title {
                                        text
                                    }
                                    content {
                                        html
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
